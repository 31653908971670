<!-- eslint-disable vue/valid-v-slot -->
<template>
<Layout>
  <v-row>
    <v-col cols="12" md="4">
      <v-card class="lighten-2 px-1 py-1 dashboard-card elevation-0" outlined>
        <v-card-title>
          <span>New Individuals</span>
        </v-card-title>
        <v-card-text>
          <span v-if="!individuals.length">No new individuals</span>
          <v-list v-else>
            <v-list-item-group v-model="selected" multiple>
              <template v-for="(individual, index) in individuals">
                <v-list-item :key="`individual-list-${index}`" inactive>
                  <template v-slot>
                    <v-list-item-content>
                      <v-list-item-title v-text="individual.clientName"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text v-text="dateFormat(individual.createdAt)"></v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider v-if="index + 1 < individuals.length" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="lighten-2 px-1 py-1 dashboard-card elevation-0" outlined>
        <v-card-title>
          <span>New Corporates</span>
        </v-card-title>
        <v-card-text>
          <span v-if="!corporates.length">No new corporates</span>
          <v-list v-else>
            <v-list-item-group v-model="selected" multiple>
              <template v-for="(corporate, index) in corporates">
                <v-list-item :key="`corporates-list-${index}`" inactive>
                  <template v-slot>
                    <v-list-item-content>
                      <v-list-item-title v-text="corporate.companyName"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="dateFormat(corporate.createdAt)"></v-list-item-action-text>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider v-if="index + 1 < corporates.length" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="red lighten-2 px-1 py-1 dashboard-card elevation-0" outlined>
        <v-card-title>
          <span class="white--text">Reminders</span>
        </v-card-title>
        <v-card-text>
          <span v-if="!reminders.length" class="white--text">Hooray! Your reminders are empty!</span>
          <v-list v-else>
            <v-list-item-group v-model="selected" multiple>
              <template v-for="(reminder, index) in reminders">
                <v-list-item :key="`reminder-list-${index}`" inactive>
                  <template v-slot class="d-flex flex-row flex-grow-1">
                    <v-list-item-content>
                      <v-list-item-title v-text="reminder.client"></v-list-item-title>
                      <v-list-item-subtitle v-text="reminderMessage(reminder)"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider v-if="index + 1 < reminders.length" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="isAdminUser">
    <v-col>
      <!-- Login records -->
      <v-card class="elevation-0" outlined>
        <p class="mt-5 ml-5">Last logins</p>
        <v-simple-table :dense="dense" :fixed-header="fixedHeader" :height="height">
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Role</th>
              <th class="text-left">Email</th>
              <th class="text-left">Phone Number</th>
              <th class="text-left">Last Access</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, uuid) in sortedUsers" :key="uuid">
              <td class="text-left">{{ user.displayName }}</td>
              <td class="text-left">{{ user.role }}</td>
              <td class="text-left">{{ user.email }}</td>
              <td class="text-left">{{ user.phoneNumber }}</td>
              <td class="text-left">{{ lastLoginTime(user) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-col>
  </v-row>
</Layout>
</template>
<style lang="scss" scooped>
.dashboard-card {
    min-height: 350px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
<script>
import Layout from '@layouts/main'
import EventBus from '@utils/EventBus'
import {
  EventBusEvents,
  documentTypes,
  AccountingDocuments
} from '@src/constants/index.js'
import {
  mapActions,
  mapGetters
} from 'vuex'
import {
  compareDesc,
  format
} from 'date-fns'
import api from '@src/api/index.js'

export default {
  name: 'Dashboard',
  components: {
    Layout,
  },
  data: () => ({
    dense: false,
    fixedHeader: false,
    height: 300,
    selected: [],
    individuals: [],
    reminders: [],
    corporates: [],
  }),
  computed: {
    ...mapGetters('user', ['isAdminUser', 'userLoading', 'users']),
    sortedUsers() {
      return [...this.users].sort((a, b) => {
        return compareDesc(
          new Date(a.lastSignInTime),
          new Date(b.lastSignInTime)
        )
      })
    },
  },
  created() {
    if (this.isAdminUser) {
      this.getAllUsers({
        showCurrent: true
      })
    }
    this.getAllIndividuals()
    this.getAllCorporates()
    this.getAllReminders()
  },
  methods: {
    ...mapActions('user', ['getAllUsers']),
    lastLoginTime(user) {
      if (user && user.lastSignInTime) {
        return format(
          new Date(user.lastSignInTime),
          "dd MMM yyyy, hh:mmaaaaa'm'"
        )
      }
      return 'n/a'
    },
    reminderMessage(reminder) {
      const {
        accountingId,
        documentId,
        riskId,
        type,
        expiryDate
      } = reminder

      if (documentId) {
        return `${documentTypes[type]} expiring on ${format(
          new Date(expiryDate),
          'dd MMM yyyy'
        )}`
      }
      if (accountingId) {
        return `${AccountingDocuments[type]} period expiring on ${format(
          new Date(expiryDate),
          'dd MMM yyyy'
        )}`
      }
      if (riskId) {
        return `Next risk review on ${format(
          new Date(expiryDate),
          'dd MMM yyyy'
        )}`
      }
    },
    async getAllReminders() {
      this.loading = true
      try {
        const reminders = await api.getAllReminders({
          limit: 5
        })
        this.loading = false
        this.reminders = reminders
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async getAllIndividuals() {
      this.loading = true
      try {
        const individuals = await api.getAllIndividuals({
          limit: 5
        })
        this.loading = false
        this.individuals = individuals
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    dateFormat(date) {
      return (
        (date && format(new Date(date), "dd MMM yyyy, hh:mmaaaaa'm'")) || ''
      )
    },
    async getAllCorporates() {
      this.loading = true
      try {
        const corporates = await api.getAllCorporates({
          limit: 5
        })
        this.loading = false
        this.corporates = corporates
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
  },
}
</script>
<style>
.text--center {
  text-align: center;
}
</style>
